.header {
    position: fixed;
    top: 0;
    transition: height 0.4s;
    width: 100%;
    min-height: 80px;
    height: fit-content;
    background-color: white;
    z-index: 10;
    font-family: "Questrial", sans-serif;
}

.logoContainer {
    position: relative;
    left: 10px;
    user-select: none;
    -webkit-user-drag: none;
    width: 120px;
    display: block;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
        border: 0;
        background-color: transparent;
        display: flex;
        height: 76px;
        align-items: center;
        cursor: pointer;
        transition: all 300ms ease-in-out;
    }
}

.linkContainer {
    background-color: white;
    margin-top: -2px;
    width: 100%;
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;
}

.instagramContainer {
    position: relative;
    margin: 18px 0;
    height: 50px;

    .instagram {
        user-select: none;
        -webkit-user-drag: none;
        display: inline-block;
        position: relative;
        width: 35px;
        height: 35px;
        mask-size: 35px 35px;
        -webkit-mask-image: url(instagram.svg);
        mask-image: url(instagram.svg);
        cursor: pointer;
    }

    .handle {
        width: 100px;
        text-align: center;
        position: absolute;
        bottom: -0px;
        font-size: 12px;
        right: -32.5px;
        font-family: 'Inconsolata', monospace;
    }
}

.linkText {
    &.active {
        color: #c78d9b;
    }
}

.linkContainer {
    box-shadow: 0 6px 6px -2px #ebe6d8;
}
.instagram {
    background-color: #c78d9b;
    margin: auto;
}

.pageLink {
    flex-basis: 100%;
    line-height: 50px;
    text-align: center;
    padding: 0;
    text-decoration: none;
    user-select: none;
    -webkit-user-drag: none;
}

a {
    text-decoration: none;
}

.linkText {
    letter-spacing: 2.4px;
    font-size: 14px;
    font-weight: 200;
    color: black;
    position: relative;
}

.linkText::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: 0;
    right: 100%;
    bottom: -4px;
    height: 3px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.linkText.active::before {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
}

.topBar,
.bottomBar {
    height: 1px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 100%;
    transition: transform 250ms cubic-bezier(.2, .6, .3, 1), width 250ms cubic-bezier(.2, .6, .3, 1);
    will-change: transform, width;
    background-color: black;
}

.menu {
    display: flex;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 22.5px;
    right: 35px;
    background-color: transparent;
    border: 0;

    &:hover {
        .topBar,
        .bottomBar {
            background-color: grey;
        }
    }
    .topBar {
        transform: translatey(-5.5px);
    }
    .bottomBar {
        transform: translatey(5.5px);
    }
}

.open {
    .linkContainer {
        display: flex;
    }

    .topBar {
        transform: translatex(3.5px) rotate(-135deg);
        width: 28px;
    }

    .bottomBar {
        transform: translatex(3.5px) rotate(135deg);
        width: 28px;
    }
}

/* Desktop */
@media (min-width: 1040px) {
    .header {
        height: 130px;

        .logoContainer {
            left: 40px;

            .logo {
                height: 120px !important;
            }
        }
        &.smaller{
            height: 100px;

            .logo {
                height: 90px !important;
            }
        }
    }

    .linkText::before {
        background: #c78d9b;
    }

    .linkContainer {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 780px;
        display: flex;
        height: 100%;
        align-items: center;
        padding-bottom: 0px;
        box-shadow: none !important;
    }
    .navbar {
        flex-wrap: nowrap;
    }
    .pageLink {
        flex-basis: 20%;
        display: inline-block;
    }
    .menu {
        display: none;
    }
    .instagramContainer  {
        margin-top: 16px;
        margin-bottom: 0;
        margin-right: 0px;
        margin-left: 8px;
    }
}

@keyframes visible {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes visible-off {
    from {
        opacity: 1;
    }

    to {
        opacity:0;
    }
}