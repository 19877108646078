.faq {
    position: relative;
    height: 105%;
    max-width: 60vh;
    margin: auto;
    top: 90px;
    font-size: 16px;
    text-align: left;
    padding: 0 16px;
    font-family: "Questrial", sans-serif;

    .underline {
        text-decoration: underline;
    }
    .lighter {
        color: dimgray;
    }

    .title {
        font-family: "Questrial", sans-serif;
        font-weight: 400;
        font-size: 28px;
        text-align: center;
        margin-bottom: 12px;
    }
    .center {
        text-align: center;
        font-size: 16px;
        margin: 4px 12px  8px;
    }
    .italic {
        font-style: italic;
    }
    .bold {
        font-weight: bold;
    }
    .subtitle {
        font-family: "Taviraj", serif;
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 12px;
    }
    .content {
        margin-left: 20px;
        text-align: left;
    }
    .larger {
        font-size: 19px;
    }

    .spacer {
        margin-bottom: 24px;
    }

    .spacer-lg {
        margin-bottom: 40px;
    }

    .buttonContainer {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 60px;
    }

    .venueButton {
        background-color: #ffe6ec;
        padding: 12px;
        border: 0;
        border-radius: 18px;
        color: black;
        font-size: 18px;
        margin: auto;

        &:hover, &:active {
            color: black;
            background-color: #ffccd8;
        }
    }
    li {
        margin: 12px;
    }

    /* Desktop */
    @media (min-width: 1040px) {
        top: 140px;
        height: 95%;
        font-size: 17px;
        padding: 16px 0;

        .buttonContainer {
            margin-bottom: 100px;
        }
        .title {
            font-size:30px;
        }
        .subtitle {
            font-size: 26px;
        }
    }
}