.aftercare {
    position: relative;
    height: 105%;
    max-width: 60vh;
    margin: auto;
    top: 90px;
    font-size: 16px;
    padding: 0 16px;
    text-align: left;
    font-family: "Questrial", sans-serif;

    .title {
        font-family: "Questrial", sans-serif;
        font-size:28px;
        margin-bottom: 12px;
        text-align: center;
    }
    .italic {
        font-style: italic;
    }
    .bold {
        font-weight: bold;
    }
    .subtitle {
        font-family: "Taviraj", serif;
        font-size: 24px;
        margin-bottom: 12px;
    }

    .spacer {
        margin-bottom: 24px;
    }

    .spacer-lg {
        margin-bottom: 40px;
    }
    li {
        margin: 6px;
    }

    /* Desktop */
    @media (min-width: 1040px) {
        top: 140px;
        height: 95%;
        font-size: 17px;
        padding: 16px 0;

        .title {
            font-size:30px;
        }
        .subtitle {
            font-size: 26px;
        }

    }
}