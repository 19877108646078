.booking {
    position: relative;
    max-width: 80vh;
    margin: auto;
    top: 90px;
    font-family: "Questrial", sans-serif;
    padding: 16px;
        
    .title {
        font-family: "Questrial", sans-serif;
        font-weight: 400;
        font-size: 26px;
        margin-bottom: 10px;
    }

    .form {
        font-family: "Questrial", sans-serif;
        width: 100%;
        margin: auto;
    
        .subtitle {
            text-align: left;
            font-family: "Taviraj", serif;
            font-weight: 400;
            font-size: 18px;
            margin-top: 8px;
            color: gray;
        }

        #bookingForm {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 4px;

            .formField {
                width: 90%;
                margin: auto;

                .flexRow {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;
                }
                .bold {
                    font-weight: 600;
                    color: black;
                }
                .dropdown,.inputField {
                    border-radius: 0;
                    font-family: "Questrial", sans-serif;
                    min-height: 28px;
                    border: 0;
                    transition: 0.3s all;
                    width: 100%;
                    margin: 4px 0;
                    padding: 0;
                    outline: none;
                    box-shadow: rgba(245, 142, 166, 0.3) 0px 1px;
                    font-size: 16px;

                    &:focus {
                        box-shadow: #f2bfcb 0 2px;
                    }
                }
                .dropdown {
                    height: 30px;
                }

                .firstName, .lastName {
                   display: flex;
                   flex-grow: 2;
                }
                .pronouns-web {
                    width: 0%;
                }
                .email { 
                    width: 50%
                }
                .react-tel-input {
                    margin: 3px 0;
                    font-size: 18px;
                    height: 30px;
                    width: 50% !important;

                    .form-control {
                        border-radius: 0;
                        border: 0;
                        box-shadow: rgba(245, 142, 166, 0.3) 0px 1px;
                        width: 100%;
                        height: 30px;

                        &:focus {
                            box-shadow: #f2bfcb 0 2px;
                        }
                    }
                
                    .flag-dropdown {
                        background-color: transparent;
                        overflow: visible;
                        border: 0;
                    }
                }

                textarea {
                    font-family: "Taviraj", serif;
                    resize: none;
                    font-size: 18px;
                }
            }

            .submit {
                font-family: "Questrial", sans-serif;
                width: 160px;
                height: 36px;
                margin: 10px auto 25px;
                padding: 2px;
                border: 0;
                border-radius: 18px;
                // color: black;
                font-size: 18px;

                &:active {
                    background-color: #ffe6ec;
                }

                :disabled:hover {
                    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
                    color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
                }
            }
        }
    }
    .checkbox-selector-label {
        font-family: "Questrial", sans-serif;
        font-size: 18px;
        line-height: 30px;
    }
    .checkbox-selector input {
        margin: 10px 0;
        display: none!important;
    }

    .checkbox-selector input[type=checkbox] + label {
        display: inline-block;
        border-radius: 6px;
        background: #dddddd;
        height: 40px;
        width: 30px;
        margin: 3px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
    }

    .checkbox-selector input[type=checkbox]:checked + label {
        background: #f2bfcb;
        color: #ffffff;
    }

    .time {
        min-width: 100px;
        font-size: 16px;
    }
    .weekday {
        min-width: 40px;
    }

    .acknowledgement {
        font-weight: 600;
        width: 90%;
        margin: 12px auto;
        text-align: left;
    
        .acknowledgementText {
            float: right;
            width: 92%;
        }
    }
    .ignore {
        display: none;
    }

    .fileUploadButtonContainer {
        margin-top: 10px;
        .uploadFileButton {
            background-color: rgb(239, 239, 239);
            display: inline-block;
            border: 1px #cfcfcf solid;
            width: 160px;
            border-radius: 18px;
            vertical-align: center;
        }
    }

    .fileUploadContainer {
        border: 1px #f2bfcb dashed;
        background-color: #fffafb;
        padding: 12px;
        border-radius: 8px;
        width: 90%;
        margin: auto;
    }

    .uploadedFilesList {
        margin-bottom: 12px;
        color: dimgray;
    }
    .disclaimer {
        color: dimgray;
        margin: 12px auto;
        font-style: italic;
        width: 90%;
        margin-bottom: 20px;
    }

    /* Desktop */
    @media only screen and (min-width: 1040px) {
        top: 140px;
        padding: 16px 0;

        .pronouns-web {
            width: 100px !important;
        }
        .form {
            width: 75%;
        }
        .title {
            font-size: 28px;
            margin-bottom: 20px;
        }
        .subtitle {
            font-size: 18px;
        }
        .dropdown,.inputField {
            font-size: 16px;
        }
        .disclaimer {
            margin-bottom: 40px;
        }
        .submit {
            margin-bottom: 60px !important;
        }
    }
}